import { FlightClass } from '@hotelplan/graphql.types';

export const mapSearchControlFlightClassToFormFlightClass = (
  flightClass: FlightClass
): FlightClass => {
  return flightClass;
};

export const mapFormFlightClassToFlightClassSearchCriteriaInput = (
  flightClass: FlightClass | null | undefined
): FlightClass => {
  return flightClass as FlightClass;
};
