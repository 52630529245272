import {
  ProductType,
  ProductClass,
  TravelType,
} from '@hotelplan/graphql.types';

export enum ExtraTravelType {
  Apartment = 'APARTMENT',
  Cruise = 'CRUISE',
  Roundtrip = 'ROUNDTRIP',
}

export const productClassDictionary: {
  [key in ProductClass]: { icon: string; title: string };
} = {
  [ProductClass.Apartment]: { title: 'common:apartments', icon: 'appartments' },
  [ProductClass.Roundtrip]: { title: 'common:roundtrip', icon: 'rundreisen' },
  [ProductClass.Cruise]: { title: 'common:cruise', icon: 'cruise' },
  [ProductClass.Hotel]: {
    icon: 'hotel',
    title: 'common:hotel',
  },
};

export const travelTypeToProductTypeDict: {
  [key in TravelType]?: ProductType;
} = {
  [TravelType.Hotel]: ProductType.Hotel,
  [TravelType.Package]: ProductType.Package,
};
