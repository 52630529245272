/* eslint-disable i18next/no-literal-string */
import pick from 'lodash/pick';
import { TravelType } from '@hotelplan/graphql.types';
import type { IFiltersTrackableData } from '@hotelplan/libs.tracking';
import { stripTypename } from '@hotelplan/libs.utils';
import {
  productFeatures as allPossibleProductFeatureFilters,
  filterCountsRecalculationRules,
} from 'components/domain/filters/Filters.constants';
import type { MainFilterComponentOptionsFragment } from 'graphql/searchFilter/MainFilterComponentOptions.generated';
import type { MainFilterComponentValuesFragment } from 'graphql/searchFilter/MainFilterComponentValues.generated';
import type {
  IFilterOption,
  IFilterCount,
  IFilterOptions,
  TFilterOptionCaptions,
  IFiltersFormState,
  TRecalculatedFilterOptionName,
  StopOverDurationType,
} from './Filters.types';

type BackendFilterKeys = keyof Omit<
  MainFilterComponentValuesFragment,
  '__typename'
>;

// Mapping between backend filter key names and frontend filter key names
const backendFilterValueKeysToFormStateFilterKey: {
  [K in BackendFilterKeys]: keyof IFiltersFormState;
} = {
  beachFeatures: 'beachFeatures',
  boardTypes: 'boardTypes',
  accommodationSize: 'accommodationSize',
  childrenFeatures: 'childrenFeatures',
  wellnessFeatures: 'wellnessFeatures',
  taRating: 'tripAdvisorRating',
  stopOvers: 'flightStopOver',
  sportFeatures: 'sportFeatures',
  roomTypes: 'roomTypes',
  directFlightDepartureTime: 'directFlightDepartureTime',
  maxPrice: 'maxPricePerPerson',
  mainFeatures: 'mainFeatures',
  popularFilters: 'popularFilters',
  hotelFeatures: 'hotelFeatures',
  cultureFeatures: 'cultureFeatures',
  hpRating: 'hotelplanRating',
  maxFlightDuration: 'maxFlightDuration',
  environmentFeatures: 'environmentFeatures',
  distanceFeatures: 'distanceFeatures',
  departureAirports: 'departureAirports',
  flightStopOverDuration: 'flightStopOverDuration',
  directFlightArrivalTime: 'directFlightArrivalTime',
  returnFlightDepartureTime: 'returnFlightDepartureTime',
  returnFlightArrivalTime: 'returnFlightArrivalTime',
  flightAirlines: 'flightAirlines',
  arrivalAirports: 'arrivalAirports',
  arrivalWeekdays: 'arrivalWeekdays',
  departureWeekdays: 'departureWeekdays',
  minPrice: 'minPrice',
  productCode: 'productCode',
  provider: 'provider',
  flightProvider: 'flightProvider',
  refundableFeatures: 'refundableFeatures',
  radius: 'radius',
};

export const flightOnlyKeys = [
  'flightStopOver',
  'flightStopOverDuration',
  'directFlightDepartureTime',
  'directFlightArrivalTime',
  'returnFlightDepartureTime',
  'returnFlightArrivalTime',
  'maxPricePerPerson',
  'flightAirlines',
  'flightProvider',
];

export const hotelOnlyKeys = [
  'mainFeatures',
  'childrenFeatures',
  'beachFeatures',
  'distanceFeatures',
  'sportFeatures',
  'wellnessFeatures',
  'hotelFeatures',
  'environmentFeatures',
  'cultureFeatures',
  'refundableFeatures',
  'tripAdvisorRating',
  'hotelplanRating',
  'roomTypes',
  'boardTypes',
  'maxPricePerPerson',
];

export const mapMainFilterComponentValuesToFiltersFormState = (
  filterValues?: MainFilterComponentValuesFragment | null
): IFiltersFormState => {
  const filtersState: IFiltersFormState = {};

  if (!filterValues) return filtersState;

  const backendFilterKeys = Object.keys(
    stripTypename(filterValues)
  ) as BackendFilterKeys[];

  backendFilterKeys.forEach(filter => {
    const filterStateKey = backendFilterValueKeysToFormStateFilterKey[filter];
    const filterValue = filterValues[filter];
    if (!filterValue) return;
    if (filterValue.__typename === 'DoubleRangeSliderFilterComponent') {
      if (filterValue.minSelected && filterValue.maxSelected) {
        (filtersState[filterStateKey] as StopOverDurationType) = {
          minSelected: filterValue.minSelected,
          maxSelected: filterValue.maxSelected,
        };
      }
    } else if (filterValue.__typename === 'CheckboxFilterComponent') {
      if (filterValue.selected.length) {
        (filtersState[filterStateKey] as string[]) = ((filtersState[
          filterStateKey
        ] || []) as string[]).concat(filterValue.selected);
      }
    } else if (
      (filterValue.__typename === 'RadiobuttonFilterComponent' ||
        filterValue.__typename === 'SliderFilterComponent') &&
      filterValue.selected
    ) {
      (filtersState[filterStateKey] as string) = filterValue.selected;
    } else if (filterValue.__typename === 'SingleValueFilterComponent') {
      (filtersState[filterStateKey] as string) = filterValue.id;
    } else if (filterValue.__typename === 'RadiusFilterComponent') {
      // @TODO add typing for filter value?
      const {
        radius,
        label,
        center: { latitude, longitude },
      } = filterValue;

      (filtersState[filterStateKey] as any) = {
        radius,
        label,
        center: {
          latitude,
          longitude,
        },
      };
    }
  });

  return filtersState;
};

export const mergeFiltersOnTravelTypeChange = (
  filtersState: IFiltersFormState,
  prevTravelType: TravelType | undefined | null,
  nextTravelType: TravelType | undefined | null,
  packageFilters: MainFilterComponentValuesFragment | undefined | null,
  hotelFilters: MainFilterComponentValuesFragment | undefined | null
): IFiltersFormState => {
  if (
    !prevTravelType ||
    !nextTravelType ||
    prevTravelType === nextTravelType ||
    prevTravelType === TravelType.Package
  ) {
    return filtersState;
  }

  const keysByTravelTypeMap = {
    [TravelType.Hotel]: hotelOnlyKeys,
    [TravelType.Flight]: flightOnlyKeys,
  };

  const keysToMerge = keysByTravelTypeMap[prevTravelType];

  if (!keysToMerge) {
    return filtersState;
  }

  const mergeProperties = pick(filtersState, keysToMerge);

  return {
    ...mapMainFilterComponentValuesToFiltersFormState(
      nextTravelType === TravelType.Package ? packageFilters : hotelFilters
    ),
    ...mergeProperties,
  };
};

// Mapping between backend filter key names and frontend filter key names
const backendFilterOptionKeysToLocalFilterOptions: {
  [K in BackendFilterKeys]: keyof IFilterOptions;
} = {
  beachFeatures: 'beachFeatures',
  boardTypes: 'boardTypes',
  accommodationSize: 'accommodationSize',
  childrenFeatures: 'childrenFeatures',
  wellnessFeatures: 'wellnessFeatures',
  taRating: 'tripAdvisorRating',
  stopOvers: 'flightStopOver',
  sportFeatures: 'sportFeatures',
  roomTypes: 'roomTypes',
  maxPrice: 'maxPricePerPerson',
  popularFilters: 'popularFilters',
  mainFeatures: 'mainFeatures',
  hotelFeatures: 'hotelFeatures',
  cultureFeatures: 'cultureFeatures',
  hpRating: 'hotelplanRating',
  maxFlightDuration: 'maxFlightDuration',
  environmentFeatures: 'environmentFeatures',
  distanceFeatures: 'distanceFeatures',
  departureAirports: 'departureAirports',
  flightStopOverDuration: 'flightStopOverDuration',
  directFlightDepartureTime: 'directFlightDepartureTime',
  directFlightArrivalTime: 'directFlightArrivalTime',
  returnFlightDepartureTime: 'returnFlightDepartureTime',
  returnFlightArrivalTime: 'returnFlightArrivalTime',
  flightAirlines: 'flightAirlines',
  arrivalAirports: 'arrivalAirports',
  arrivalWeekdays: 'arrivalWeekdays',
  departureWeekdays: 'departureWeekdays',
  minPrice: 'minPrice',
  productCode: 'productCode',
  provider: 'provider',
  flightProvider: 'flightProvider',
  refundableFeatures: 'refundableFeatures',
  radius: 'radius',
};

export const mapMainFilterComponentOptionsToLocalFilterOptionCaptions = (
  options: MainFilterComponentOptionsFragment
): TFilterOptionCaptions => {
  const backendFilterKeys = Object.keys(
    stripTypename(options)
  ) as BackendFilterKeys[];
  const resultOptions = {} as TFilterOptionCaptions;

  backendFilterKeys.forEach(filter => {
    const filterOptionKey = backendFilterOptionKeysToLocalFilterOptions[filter];
    const filterOption = options[filter];

    if (!filterOption) return;
    if (
      filterOption.__typename === 'CheckboxFilterComponent' ||
      filterOption.__typename === 'RadiobuttonFilterComponent' ||
      filterOption.__typename === 'SliderFilterComponent' ||
      filterOption.__typename === 'DoubleRangeSliderFilterComponent'
    ) {
      resultOptions[filterOptionKey] = filterOption.caption;

      if (
        filterOptionKey === 'tripAdvisorRating' ||
        filterOptionKey === 'hotelplanRating'
      ) {
        resultOptions.ratings = filterOption.caption;
      }

      if (
        filterOptionKey === 'directFlightDepartureTime' ||
        filterOptionKey === 'returnFlightDepartureTime'
      ) {
        resultOptions.flightOptions = filterOption.caption;
      }

      if (
        filterOptionKey === 'directFlightDepartureTime' ||
        filterOptionKey === 'returnFlightDepartureTime' ||
        filterOptionKey === 'directFlightArrivalTime' ||
        filterOptionKey === 'returnFlightArrivalTime'
      ) {
        resultOptions.flightDepartureArrivalTimes = filterOption.caption;
      }
    }
  });

  return resultOptions;
};

export const mapMainFilterComponentOptionsToLocalFilterOptions = (
  options: MainFilterComponentOptionsFragment
): IFilterOptions => {
  const backendFilterKeys = Object.keys(
    stripTypename(options)
  ) as BackendFilterKeys[];
  const resultOptions = {} as IFilterOptions;

  backendFilterKeys.forEach(filter => {
    const filterOptionKey = backendFilterOptionKeysToLocalFilterOptions[filter];
    const filterOption = options[filter];

    if (!filterOption) return;
    if (
      filterOption.__typename === 'CheckboxFilterComponent' ||
      filterOption.__typename === 'RadiobuttonFilterComponent'
    ) {
      const filterOptions: IFilterOption[] = filterOption.options.map(o => ({
        caption: o.caption,
        id: o.id,
      }));
      // TODO: add custom typeguard
      if (
        filterOptionKey !== 'maxPricePerPerson' &&
        filterOptionKey !== 'maxFlightDuration' &&
        filterOptionKey !== 'minPrice' &&
        filterOptionKey !== 'productCode' &&
        filterOptionKey !== 'provider' &&
        filterOptionKey !== 'flightProvider'
      ) {
        resultOptions[filterOptionKey] = filterOptions;
      }
    }

    if (filterOption.__typename === 'SliderFilterComponent') {
      // TODO: add custom typeguard
      if (
        filterOptionKey === 'maxPricePerPerson' ||
        filterOptionKey === 'maxFlightDuration'
      ) {
        resultOptions[filterOptionKey] = {
          caption: filterOption.caption,
          id: filterOptionKey,
        };
      }
    }
  });

  return resultOptions;
};

export const shouldRecalculateCountsWithinFilter = (
  prevChangedFilter: TRecalculatedFilterOptionName | null | undefined,
  currentChangedFilter: TRecalculatedFilterOptionName,
  prevCounts: number | IFilterCount | undefined
): boolean => {
  if (prevChangedFilter !== currentChangedFilter) {
    return true;
  }

  if (typeof prevCounts === 'undefined') {
    return true;
  }

  const defaultResult =
    filterCountsRecalculationRules[currentChangedFilter]
      .recalculateWithinFilter;

  return defaultResult;
};

interface IBaseOptionsFilter {
  options: Array<{ productCount: number; id: string }>;
}

export const mapFilterToCountsMapper = <T extends IBaseOptionsFilter>(
  filter?: T | null
): IFilterCount[] | undefined => {
  if (typeof filter?.options === 'object') {
    return filter.options.map(item => ({
      count: item.productCount,
      id: item.id,
    }));
  }

  return undefined;
};

export const mapFiltersStateToFilterCriteriaInput = <
  TFilterCriteria extends {
    maxPricePerPerson?: string | null | undefined;
    productFeatures?: string[] | null | undefined;
  }
>(
  filters: IFiltersFormState | null | undefined,
  formStateToCriteriaDict: {
    [K in keyof IFiltersFormState]: keyof TFilterCriteria;
  }
): TFilterCriteria => {
  if (!filters) return {} as TFilterCriteria;
  const criteria = (Object.keys(filters) as Array<
    keyof IFiltersFormState
  >).reduce<TFilterCriteria>((acc, key) => {
    const criteriaKey = formStateToCriteriaDict[key];
    if (!criteriaKey) return acc;
    const filterValue = filters[key];
    if (typeof filterValue === 'undefined') return acc;
    return { ...acc, [criteriaKey]: filterValue };
  }, {} as TFilterCriteria);
  criteria.maxPricePerPerson = criteria.maxPricePerPerson || undefined;
  const productFeatures = getAllProductFeatures(filters);
  if (productFeatures.length > 0) {
    criteria.productFeatures = productFeatures;
  }
  return criteria;
};

export const mapFiltersStateToTrackableData = (
  state?: IFiltersFormState | null
): IFiltersTrackableData | null | undefined => {
  if (!state) return;

  const productFeatures = getAllProductFeatures(state);

  return {
    attributes: {
      keys: productFeatures.length > 0 ? productFeatures : undefined,
    },
    flightOptions: {
      departure: state?.directFlightDepartureTime ?? undefined,
      arrival: state?.returnFlightDepartureTime ?? undefined,
      stopOvers: state?.flightStopOver ?? undefined,
      airLines: state?.flightAirlines ?? undefined,
      stopOverTime: state?.flightStopOverDuration
        ? {
            min: state.flightStopOverDuration.minSelected,
            max: state.flightStopOverDuration.maxSelected,
          }
        : undefined,
    },
    hotelPlanRating: state?.hotelplanRating ?? undefined,
    tripAdvisorRating: state?.tripAdvisorRating ?? undefined,
    price: {
      max: state?.maxPricePerPerson ?? undefined,
    },
    room: {
      boards: state?.boardTypes ?? undefined,
      types: state?.roomTypes ?? undefined,
    },
  };
};

const getAllProductFeatures = (state: IFiltersFormState): string[] => {
  const productFeatures = [];
  allPossibleProductFeatureFilters.forEach(key => {
    const features = state[key] as string[];
    if (features) {
      productFeatures.push(...features);
    }
  });
  return productFeatures;
};
