import {
  getDurationValuesBySearchType,
  updateDuration,
} from '@hotelplan/components.common.travel-dates';
import type { ITravelDatesState } from '@hotelplan/components.common.travel-dates';
import { FlightType, SearchPeriodType } from '@hotelplan/graphql.types';
import { prepareDatesInFlightPartitions } from 'components/domain/flightPartitions/FlightPartitions.utils';
import { IFlightSearchControlFormState } from 'components/domain/searchControl/SearchControl.types';

export const mapSearchControlFlightTypeToFormFlightType = (
  flightType: FlightType
): FlightType => {
  return flightType;
};

export const mapFormFlightTypeToFlightTypeSearchCriteriaInput = (
  flightType: FlightType | null | undefined
): FlightType => {
  return flightType as FlightType;
};

export function mergeFlightTypeToFlightSearchState(
  currentState: IFlightSearchControlFormState,
  nextFlightType: FlightType
): IFlightSearchControlFormState {
  let flightPartitions = currentState.flightPartitions?.map(partition => {
    if (!partition.travelDates) return { ...partition };

    const durationValues = getDurationValuesBySearchType(
      SearchPeriodType.Exact
    );

    const updatedDuration = updateDuration(
      partition.travelDates.departureDate,
      partition.travelDates.departureDate,
      partition.travelDates.duration,
      durationValues,
      SearchPeriodType.Exact
    );

    return {
      ...partition,
      travelDates: {
        ...partition.travelDates,
        returnDate:
          nextFlightType === FlightType.Return
            ? partition.travelDates.departureDate
            : null,
        duration: updatedDuration,
      } as ITravelDatesState,
    };
  });

  if (nextFlightType === FlightType.OpenJaw) {
    flightPartitions = prepareDatesInFlightPartitions(flightPartitions);
  }

  return {
    ...currentState,
    flightType: nextFlightType,
    flightPartitions,
  };
}
