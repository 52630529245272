import {
  FlightPartition as BackendFlightPartition,
  FlightType,
} from '@hotelplan/graphql.types';
import { formatDate } from '@hotelplan/libs.utils';
import { FlightPartition } from 'components/domain/flightPartitions/FlightPartitions.types';

const SHORT_DATE_INFO_FORMAT = 'dd.MM.yyyy';

export const prepareFlightPartitionsToFormState = (
  flightPartitions: BackendFlightPartition[] | null | undefined,
  flightType: FlightType
): BackendFlightPartition[] | null | undefined => {
  // NOTE: If it's not an OpenJaw flight type then we need to add initial values to the second flight partitions.
  return flightPartitions && flightType !== FlightType.OpenJaw
    ? [...flightPartitions, { ...flightPartitions[0] }]
    : flightPartitions;
};

export const prepareFlightPartitionsToCriteriaInput = (
  flightPartitions: FlightPartition[] | null | undefined,
  flightType: FlightType | null | undefined
): FlightPartition[] | null | undefined => {
  // NOTE: If it's not an OpenJaw flight type then we need to remove unnecessary flight partitions.
  return flightPartitions && flightType !== FlightType.OpenJaw
    ? flightPartitions.slice(0, 1)
    : flightPartitions;
};

export function mergeFlightPartitionWithFGSS(
  partition: FlightPartition,
  globalSearchPartition: FlightPartition | null = null
): FlightPartition {
  if (!globalSearchPartition) return { ...partition };

  const resultTravelDates =
    globalSearchPartition.travelDates || partition.travelDates;

  return {
    ...partition,
    ...globalSearchPartition,
    travelDates: resultTravelDates
      ? {
          ...resultTravelDates,
          defaults: partition.travelDates?.defaults,
        }
      : null,
  };
}

export const mergeFlightPartitionsWithFGSS = (
  flightPartitions: FlightPartition[] | null | undefined,
  globalSearchFlightPartitions: FlightPartition[] | null | undefined
): FlightPartition[] | null | undefined => {
  return flightPartitions?.map((partition, i) => {
    let globalSearchPartition = globalSearchFlightPartitions
      ? globalSearchFlightPartitions[i]
      : null;

    // NOTE: If there is no such partition in the FGSS we should try to populate it with the data from the
    // first partition in the FGSS.
    if (!globalSearchPartition && globalSearchFlightPartitions && i > 0) {
      globalSearchPartition = globalSearchFlightPartitions[0];
    }

    return mergeFlightPartitionWithFGSS(partition, globalSearchPartition);
  });
};

export const arePartitionsValid = (
  flightPartitions:
    | Array<FlightPartition | BackendFlightPartition>
    | null
    | undefined,
  flightType: FlightType | null | undefined
): boolean => {
  const partitionsToCheck =
    flightType === FlightType.OpenJaw
      ? flightPartitions
      : flightPartitions?.slice(0, 1);

  if (!partitionsToCheck) return false;

  // NOTE: Airport fields are mandatory to select.
  return partitionsToCheck.every(
    (partition: FlightPartition | BackendFlightPartition) =>
      !!partition.arrivalAirport && !!partition.departureAirport
  );
};

export const getShortInformation = (
  flightPartition: FlightPartition,
  isReturnFlight: boolean
): {
  airports: string;
  travelDates: string;
} => {
  const returnDate = flightPartition.travelDates?.returnDate;
  const departureDate = flightPartition.travelDates?.departureDate;

  const formattedReturnDate = returnDate
    ? formatDate(returnDate, SHORT_DATE_INFO_FORMAT)
    : '...';
  const formattedDepartureDate = departureDate
    ? formatDate(departureDate, SHORT_DATE_INFO_FORMAT)
    : '...';
  const formattedArrivalAirport = flightPartition.arrivalAirport
    ? `${flightPartition.arrivalAirport.name} (${flightPartition.arrivalAirport.iata})`
    : '...';
  const formattedDepartureAirport = flightPartition.departureAirport
    ? `${flightPartition.departureAirport.name} (${flightPartition.departureAirport.iata})`
    : '...';

  return {
    airports: `${formattedDepartureAirport} - ${formattedArrivalAirport}`,
    travelDates: isReturnFlight
      ? `${formattedDepartureDate} - ${formattedReturnDate}`
      : formattedDepartureDate,
  };
};

export const prepareDatesInFlightPartitions = (
  flightPartitions: FlightPartition[] | undefined
): FlightPartition[] | undefined => {
  if (!flightPartitions) return flightPartitions;

  const firstPartitionDate = flightPartitions[0].travelDates?.departureDate;
  const secondPartitionDate = flightPartitions[1].travelDates?.departureDate;

  if (
    firstPartitionDate &&
    secondPartitionDate &&
    firstPartitionDate > secondPartitionDate
  ) {
    return flightPartitions.map((partition, index) => {
      return index === 1
        ? { ...partition, travelDates: flightPartitions[0].travelDates }
        : { ...partition };
    });
  }

  return flightPartitions;
};
