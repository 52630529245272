import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { TravelType } from '@hotelplan/graphql.types';
import { TravelType as TrackingTravelType } from '@hotelplan/libs.tracking';
import { ExtraTravelType } from 'components/domain/travelTypes/TravelType.constants';
import type { TravelTypeData } from './TravelType.types';

const travelTypeToTrackingType: {
  [K in TravelType | ExtraTravelType]: TrackingTravelType;
} = {
  [ExtraTravelType.Apartment]: 'apartments',
  [ExtraTravelType.Cruise]: 'cruise',
  [TravelType.Flight]: 'flight',
  [TravelType.Hotel]: 'hotel',
  [TravelType.Package]: 'package',
  [ExtraTravelType.Roundtrip]: 'roundtrip',
};

export const mapTravelTypeToTrackableTravelType = (
  travelType: TravelType | ExtraTravelType
) => {
  return travelTypeToTrackingType[travelType];
};

export const mapTravelTypeToTravelTypeData = (
  travelType: TravelType | ExtraTravelType,
  channelType?: AuthChannelType
) => {
  if (channelType == AuthChannelType.B2B && travelTypesMapB2B[travelType]) {
    return travelTypesMapB2B[travelType];
  }

  return travelTypesMap[travelType];
};

const travelTypesMap: TravelTypeData = {
  [TravelType.Package]: {
    searchParam: TravelType.Package,
    type: TravelType.Package,
    label: 'common:package.plus',
    icon: 'flughotel',
    testId: 'travelTypesPackage',
  },
  [TravelType.Hotel]: {
    searchParam: TravelType.Hotel,
    type: TravelType.Hotel,
    label: 'common:hotel',
    icon: 'hotel',
    testId: 'travelTypesHotel',
  },
  [ExtraTravelType.Apartment]: {
    type: ExtraTravelType.Apartment,
    link: {
      de: '/ferienwohnungen' as const,
      fr: '/appartments' as const,
    },
    label: 'common:apartments',
    icon: 'appartments',
    testId: 'travelTypesApartment',
  },
  [TravelType.Flight]: {
    searchParam: TravelType.Flight,
    type: TravelType.Flight,
    label: 'common:flight',
    icon: 'flug',
    testId: 'travelTypesFlight',
  },
  [ExtraTravelType.Cruise]: {
    type: ExtraTravelType.Cruise,
    label: 'common:search.control.cruise',
    link: {
      de: 'https://kreuzfahrten.hotelplan.ch/home',
      fr: 'https://croisieres.hotelplan.ch/home',
    },
    isExternal: true,
    icon: 'cruise',
    testId: 'travelTypesCruise',
  },
  [ExtraTravelType.Roundtrip]: {
    type: ExtraTravelType.Roundtrip,
    link: {
      de: '/ferien/rundreisen/t-4' as const,
      fr: '/vacances/circuits/t-4' as const,
    },
    label: 'common:roundtrip_other' as const,
    icon: 'rundreisen',
    testId: 'travelTypesRoundtrip',
  },
};

const travelTypesMapB2B: Pick<TravelTypeData, ExtraTravelType.Apartment> = {
  [ExtraTravelType.Apartment]: {
    type: ExtraTravelType.Apartment,
    link: {
      de: 'https://www.interhome.group/de-ch/partnerprogramm' as const,
      fr: 'https://www.interhome.group/fr-ch/programme-partenaire' as const,
    },
    label: 'common:apartments',
    icon: 'appartments',
    testId: 'travelTypesApartment',
  },
};
