import clone from 'lodash/clone';
import {
  IInputTravelDestination,
  ITravelDestinationState,
  mapFormTravelDestinationToSearchCriteriaInputTravelDestination as mapTravelDestination,
} from '@hotelplan/components.common.travel-destination';

export const mapFormTravelDestinationToSearchCriteriaInputTravelDestination = (
  travelDestination: ITravelDestinationState | undefined | null
): Array<IInputTravelDestination> | undefined | null => {
  let travelDestinationToMap = travelDestination;

  //HMWEBDEV-5789 if "all destinations" selected then all other destinations will be ignored
  if (travelDestination?.destinations?.find(i => !i.id)) {
    travelDestinationToMap = clone(travelDestination);
    travelDestinationToMap.destinations = [];
  }

  return mapTravelDestination(travelDestinationToMap);
};
