import { mapTravelDatesModalToTrackableTravelDates } from '@hotelplan/components.common.travel-dates';
import { TravelDatesPayload } from '@hotelplan/libs.tracking';
import type { FlightPartition } from 'components/domain/flightPartitions/FlightPartitions.types';

export const mapFlightTravelDatesModalToTrackableTravelDates = (
  flightPartitions: FlightPartition[]
): TravelDatesPayload | undefined => {
  if (!flightPartitions.length) return undefined;

  if (flightPartitions.length === 1) {
    return flightPartitions[0].travelDates
      ? mapTravelDatesModalToTrackableTravelDates(
          flightPartitions[0].travelDates
        )
      : undefined;
  }

  return {
    from: flightPartitions[0].travelDates?.departureDate || '',
    to: flightPartitions[1].travelDates?.departureDate || '',
  };
};
