/* eslint-disable i18next/no-literal-string */
import type {
  IFiltersFormState,
  TFilterOptionName,
  TFilterOptionOrderName,
  TFilterCountsRecalculationRules,
} from './Filters.types';

export const productFeatures: TFilterOptionName[] = [
  'mainFeatures',
  'childrenFeatures',
  'beachFeatures',
  'distanceFeatures',
  'sportFeatures',
  'wellnessFeatures',
  'hotelFeatures',
  'environmentFeatures',
  'cultureFeatures',
  'refundableFeatures',
];

export const allFilters: TFilterOptionName[] = [
  'tripAdvisorRating',
  'hotelplanRating',
  'flightStopOver',
  'returnFlightDepartureTime',
  'roomTypes',
  'directFlightDepartureTime',
  'boardTypes',
  'departureAirports',
  'maxPricePerPerson',
  'maxFlightDuration',
  ...productFeatures,
];

const [mainFeatures, ...additionalFeatures] = productFeatures;

export const hotelsFiltersOrder: TFilterOptionOrderName[] = [
  mainFeatures,
  'roomTypes',
  'accommodationSize',
  'boardTypes',
  'ratings',
  'maxPricePerPerson',
  ...additionalFeatures,
];

export const packageFiltersOrder: TFilterOptionOrderName[] = [
  mainFeatures,
  'roomTypes',
  'accommodationSize',
  'boardTypes',
  'ratings',
  'maxPricePerPerson',
  'departureAirports',
  'flightOptions',
  'flightStopOver',
  'maxFlightDuration',
  ...additionalFeatures,
];

export const packageFiltersOrderWithPopularFilter: TFilterOptionOrderName[] = [
  'popularFilters',
  mainFeatures,
  'roomTypes',
  'boardTypes',
  'ratings',
  'maxPricePerPerson',
  'departureAirports',
  'flightOptions',
  'flightStopOver',
  'maxFlightDuration',
  ...additionalFeatures,
];

export const flightFiltersOrder: TFilterOptionOrderName[] = [
  'flightStopOver',
  'flightStopOverDuration',
  'flightDepartureArrivalTimes',
  'maxPricePerPerson',
  'flightAirlines',
];

// @todo: check logic
export const filterCountsRecalculationRules: TFilterCountsRecalculationRules = {
  roomTypes: {
    logic: 'OR',
    recalculateWithinFilter: false,
  },
  accommodationSize: {
    logic: 'OR',
    recalculateWithinFilter: false,
  },
  boardTypes: {
    logic: 'OR',
    recalculateWithinFilter: false,
  },
  hotelplanRating: {
    recalculateWithinFilter: false,
  },
  tripAdvisorRating: {
    recalculateWithinFilter: false,
  },
  departureAirports: {
    logic: 'OR',
    recalculateWithinFilter: false,
  },
  maxFlightDuration: {
    recalculateWithinFilter: false,
  },
  flightStopOver: {
    recalculateWithinFilter: false,
  },
  maxPricePerPerson: {
    recalculateWithinFilter: false,
  },
  popularFilters: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  mainFeatures: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  beachFeatures: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  childrenFeatures: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  cultureFeatures: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  distanceFeatures: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  environmentFeatures: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  hotelFeatures: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  sportFeatures: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  wellnessFeatures: {
    logic: 'AND',
    recalculateWithinFilter: true,
  },
  flightAirlines: {
    logic: 'OR',
    recalculateWithinFilter: false,
  },
  flightStopOverDuration: {
    recalculateWithinFilter: false,
  },
  directFlightDepartureTime: {
    recalculateWithinFilter: false,
  },
  directFlightArrivalTime: {
    recalculateWithinFilter: false,
  },
  returnFlightDepartureTime: {
    recalculateWithinFilter: false,
  },
  returnFlightArrivalTime: {
    recalculateWithinFilter: false,
  },
};

export const FilterOptions: { [F in keyof IFiltersFormState]: F } = {
  arrivalAirports: 'arrivalAirports',
  arrivalWeekdays: 'arrivalWeekdays',
  departureWeekdays: 'departureWeekdays',
  minPrice: 'minPrice',
  popularFilters: 'popularFilters',
  flightAirlines: 'flightAirlines',
  flightStopOverDuration: 'flightStopOverDuration',
  tripAdvisorRating: 'tripAdvisorRating',
  hotelplanRating: 'hotelplanRating',
  flightStopOver: 'flightStopOver',
  returnFlightDepartureTime: 'returnFlightDepartureTime',
  roomTypes: 'roomTypes',
  accommodationSize: 'accommodationSize',
  directFlightDepartureTime: 'directFlightDepartureTime',
  boardTypes: 'boardTypes',
  departureAirports: 'departureAirports',
  maxPricePerPerson: 'maxPricePerPerson',
  maxFlightDuration: 'maxFlightDuration',
  directFlightArrivalTime: 'directFlightArrivalTime',
  returnFlightArrivalTime: 'returnFlightArrivalTime',
  mainFeatures: 'mainFeatures',
  childrenFeatures: 'childrenFeatures',
  beachFeatures: 'beachFeatures',
  distanceFeatures: 'distanceFeatures',
  sportFeatures: 'sportFeatures',
  wellnessFeatures: 'wellnessFeatures',
  hotelFeatures: 'hotelFeatures',
  environmentFeatures: 'environmentFeatures',
  cultureFeatures: 'cultureFeatures',
  refundableFeatures: 'refundableFeatures',
  productCode: 'productCode',
  provider: 'provider',
  flightProvider: 'flightProvider',
  radius: 'radius',
};
