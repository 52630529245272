import isEmpty from 'lodash/isEmpty';
import {
  Airport,
  TravelDestination,
  TravelDestinationType,
  AirportInput,
} from '@hotelplan/graphql.types';
import type { IAirport } from '@hotelplan/libs.tracking';
import { ICanonicalAirport } from 'components/domain/flightAirport/FlightAirport.types';

export function mapTravelDestinationObjectToCanonicalObject(
  destination: TravelDestination | null | undefined
): ICanonicalAirport | null {
  if (
    !destination ||
    ![
      TravelDestinationType.Airport,
      TravelDestinationType.AirportGroup,
    ].includes(destination.type)
  )
    return null;

  return {
    id: destination.id,
    iata: destination.location?.airport?.iata || '',
    name: destination.location?.airport?.name || '',
    isGroup: destination.type === TravelDestinationType.AirportGroup,
  };
}

export function mapAirportObjectToCanonicalObject(
  airport: Airport | null | undefined
): ICanonicalAirport | null {
  if (!airport) return null;

  return {
    id: airport?.iata || '',
    iata: airport?.iata || '',
    name: airport?.name || '',
  };
}

export function mapCanonicalAirportToIDs(airport: ICanonicalAirport): string {
  return airport.id;
}

export function mapFormAirportToFlightSearchCriteriaAirportInput(
  airport: ICanonicalAirport | null | undefined
): AirportInput | undefined {
  return !isEmpty(airport?.iata)
    ? {
        iata: airport?.iata,
        name: airport?.name,
      }
    : undefined;
}

export const mapCanonicalFlightAirportToTrackableFlightAirport = (
  airport: ICanonicalAirport
): IAirport => {
  return {
    code: airport.iata,
    label: airport.name,
  };
};
