import {
  mapExactTravelPeriodToFormTravelDates,
  mapTravelDatesStateToExactTravelPeriodInput,
} from '@hotelplan/components.common.travel-dates';
import {
  FlightPartition as BackendFlightPartition,
  FlightPartitionInput,
} from '@hotelplan/graphql.types';
import {
  mapAirportObjectToCanonicalObject,
  mapFormAirportToFlightSearchCriteriaAirportInput,
} from 'components/domain/flightAirport/FlightAirport.mappers';
import {
  mapFormFlightClassToFlightClassSearchCriteriaInput,
  mapSearchControlFlightClassToFormFlightClass,
} from 'components/domain/flightClass/FlightClass.mappers';
import { FlightPartition } from 'components/domain/flightPartitions/FlightPartitions.types';

export function mapSearchControlFlightPartitionsToFormFlightPartitions(
  flightPartitions: BackendFlightPartition[] | null | undefined
): FlightPartition[] {
  if (!flightPartitions) return [];

  return flightPartitions.map(partition => ({
    flightClass: mapSearchControlFlightClassToFormFlightClass(
      partition.flightClass
    ),
    travelDates: mapExactTravelPeriodToFormTravelDates(partition.travelPeriod),
    departureAirport: mapAirportObjectToCanonicalObject(
      partition.departureAirport
    ),
    arrivalAirport: mapAirportObjectToCanonicalObject(partition.arrivalAirport),
  }));
}

export function mapFormFlightPartitionsToFlightPartitionsCriteriaInput(
  flightPartitions: FlightPartition[] | null | undefined
): FlightPartitionInput[] {
  if (!flightPartitions) return [];

  return flightPartitions.map(partition => ({
    flightClass: mapFormFlightClassToFlightClassSearchCriteriaInput(
      partition.flightClass
    ),
    departureAirport: mapFormAirportToFlightSearchCriteriaAirportInput(
      partition?.departureAirport
    ),
    arrivalAirport: mapFormAirportToFlightSearchCriteriaAirportInput(
      partition?.arrivalAirport
    ),
    travelPeriod: mapTravelDatesStateToExactTravelPeriodInput(
      partition?.travelDates
    ),
  }));
}
