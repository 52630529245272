import {
  adjustTravelDates,
  mapSearchControlTravelPeriodToFormTravelDates,
  mapTravelDatesModalToTrackableTravelDates,
  mapTravelDatesModelToTravelPeriodCriteria,
  mergeTravelDatesState,
} from '@hotelplan/components.common.travel-dates';
import {
  mapCanonicalDestinationToTrackableDestination,
  mapSearchControlTravelDestinationsToFormTravelDestination,
} from '@hotelplan/components.common.travel-destination';
import {
  mapFormTravelRoomsToSearchCriteriaInputRooms,
  mapFormTravelRoomsToSearchCriteriaInputTravellers,
  mapSearchControlTravelRoomsToFormTravelRooms,
} from '@hotelplan/components.common.travel-rooms';
import { FlightType, TravelType } from '@hotelplan/graphql.types';
import { ISearchControlTrackableData } from '@hotelplan/libs.tracking';
import { mapFlightTravelDatesModalToTrackableTravelDates } from 'components/domain/flight-travel-dates/FlightTravelDates.mappers';
import { mapSearchControlFlightPartitionsToFormFlightPartitions } from 'components/domain/flightPartitions/FlightPartitions.mappers';
import {
  mergeFlightPartitionsWithFGSS,
  prepareDatesInFlightPartitions,
  prepareFlightPartitionsToCriteriaInput,
  prepareFlightPartitionsToFormState,
} from 'components/domain/flightPartitions/FlightPartitions.utils';
import { mapSearchControlFlightTypeToFormFlightType } from 'components/domain/flightTypes/FlightTypes.mappers';
import { mapFormTravelDestinationToSearchCriteriaInputTravelDestination } from 'components/domain/travel-destination/TravelDestination.mappers';
import { mapTravelTypeToTrackableTravelType } from 'components/domain/travelTypes/TravelTypes.mappers';
import { UnionSearchControlFragment } from 'graphql/searchControl/UnionSearchControl.generated';
import {
  IFlightSearchControlFormState,
  IFlightSearchControlUnionType,
  IHotelSearchControlUnionType,
  ISearchControlFormState,
  ISearchControlState,
  ISearchCriteriaInput,
} from './SearchControl.types';

export const getSearchControlValuesToFormStateMapper = <TControl, TFormState>(
  get: (from: Omit<TControl, '__typename'>) => TFormState | null
) => (obj: Omit<TControl, '__typename'>) => {
  return get(obj);
};

export function mapSearchControlToCriteria<
  S extends ISearchControlFormState | undefined | null
>(formState: S): ISearchCriteriaInput {
  return {
    travellers: mapFormTravelRoomsToSearchCriteriaInputTravellers(
      formState?.travelRooms
    ),
    rooms: mapFormTravelRoomsToSearchCriteriaInputRooms(formState?.travelRooms),
    travelPeriod: mapTravelDatesModelToTravelPeriodCriteria(
      formState?.travelDates
    ),
    travelType: formState?.travelType,
    travelDestinations: mapFormTravelDestinationToSearchCriteriaInputTravelDestination(
      formState?.travelDestination
    ),
    travellersDistribution: formState?.travelRooms.travellersDistribution,
  };
}

export const mapFlightSearchControlFormStateToTrackableData = (
  state?: IFlightSearchControlFormState | null
): ISearchControlTrackableData | null | undefined => {
  if (!state) return;

  const flightPartitions = prepareFlightPartitionsToCriteriaInput(
    state.flightPartitions,
    state.flightType
  );

  const travelPeriod = flightPartitions
    ? mapFlightTravelDatesModalToTrackableTravelDates(flightPartitions)
    : undefined;

  const departureAirports =
    flightPartitions?.map(
      ({ departureAirport }) => departureAirport?.iata || ''
    ) || [];
  const returnAirports =
    flightPartitions?.map(({ arrivalAirport }) => arrivalAirport?.iata || '') ||
    [];

  return {
    travelPeriod,
    type: mapTravelTypeToTrackableTravelType(
      state?.travelType || TravelType.Package
    ),
    travellers: state?.travelRooms
      ? {
          adults: state.travelRooms.adults,
          children: state.travelRooms.childrenDobs,
          rooms: state.travelRooms.rooms,
          travellersDistribution: state.travelRooms.travellersDistribution,
        }
      : undefined,
    airports: flightPartitions
      ? {
          departure: departureAirports,
          return: returnAirports,
        }
      : undefined,
  };
};

export const mapSearchControlFormStateToTrackableData = (
  state?: ISearchControlFormState | null
): ISearchControlTrackableData | null | undefined => {
  if (!state) return;

  return {
    type: mapTravelTypeToTrackableTravelType(
      state?.travelType || TravelType.Package
    ),
    travelPeriod: state?.travelDates
      ? mapTravelDatesModalToTrackableTravelDates(state.travelDates)
      : undefined,
    text: (state?.travelDestination?.destinations || []).map(
      mapCanonicalDestinationToTrackableDestination
    ),
    travellers: state?.travelRooms
      ? {
          adults: state.travelRooms.adults,
          children: state.travelRooms.childrenDobs,
          rooms: state.travelRooms.rooms,
          travellersDistribution: state.travelRooms.travellersDistribution,
        }
      : undefined,
  };
};

export const mapSearchControlStateToTrackableData = (
  state?: ISearchControlState | null
): ISearchControlTrackableData | null | undefined => {
  if (!state) return;

  if (state.type === 'HOTEL') {
    return mapSearchControlFormStateToTrackableData(state);
  } else {
    // NOTE: Only for the Flight Search Control.
    return mapFlightSearchControlFormStateToTrackableData(state);
  }
};

export const mapSearchControlValuesToFormState = getSearchControlValuesToFormStateMapper<
  UnionSearchControlFragment | undefined,
  ISearchControlState
>((searchControl: UnionSearchControlFragment | undefined) => {
  if (!searchControl) return null;

  if (searchControl.__typename === 'SearchControlComponent') {
    return {
      type: 'HOTEL',
      travelType: searchControl.travelType,
      travelDates: mapSearchControlTravelPeriodToFormTravelDates(
        searchControl.period
      ),
      travelRooms: mapSearchControlTravelRoomsToFormTravelRooms(
        searchControl.travellers,
        searchControl.rooms,
        searchControl.travellersDistribution
      ),
      travelDestination: mapSearchControlTravelDestinationsToFormTravelDestination(
        searchControl.destinations
      ),
    };
  } else if (searchControl.__typename === 'FlightSearchControlComponent') {
    const flightPartitions = prepareFlightPartitionsToFormState(
      searchControl.flightPartitions,
      searchControl.flightType
    );

    return {
      type: 'FLIGHT',
      travelType: searchControl.travelType,
      flightType: mapSearchControlFlightTypeToFormFlightType(
        searchControl.flightType
      ),
      travelRooms: mapSearchControlTravelRoomsToFormTravelRooms(
        searchControl.travellers,
        0 // NOTE: Set rooms to 0 to get rid of rooms selection on the Flight Search Control
      ),
      flightPartitions: mapSearchControlFlightPartitionsToFormFlightPartitions(
        flightPartitions
      ),
    };
  } else {
    return null;
  }
});

export function mergeHotelSearchControlState(
  searchControlState?: ISearchControlFormState | null,
  globalSearchState?: ISearchControlFormState | null
): IHotelSearchControlUnionType | null {
  if (!searchControlState) return null;

  return {
    type: 'HOTEL',
    ...searchControlState,
    ...globalSearchState,
    travelDates: mergeTravelDatesState(
      searchControlState?.travelDates,
      globalSearchState?.travelDates
    ),
  };
}

export function mergeFlightSearchControlState(
  searchControlState?: IFlightSearchControlFormState | null,
  flightGlobalSearchState?: IFlightSearchControlFormState | null
): IFlightSearchControlUnionType | null {
  if (!searchControlState) return null;

  let flightPartitions = mergeFlightPartitionsWithFGSS(
    searchControlState.flightPartitions,
    flightGlobalSearchState?.flightPartitions
  );

  const flightType =
    flightGlobalSearchState?.flightType || searchControlState.flightType;

  // reset return date if it is not return type, for proper adjustment
  if (flightPartitions && flightType !== FlightType.Return) {
    flightPartitions.forEach(partition => {
      if (partition.travelDates) {
        partition.travelDates.returnDate = null;
      }
    });
  }

  // adjust travel dates for each partition, see HPWEBN-2002
  if (flightPartitions) {
    flightPartitions = flightPartitions.map(partition => ({
      ...partition,
      travelDates: adjustTravelDates(partition.travelDates),
    }));

    flightPartitions = prepareDatesInFlightPartitions(flightPartitions);
  }

  return {
    type: 'FLIGHT',
    ...searchControlState,
    ...flightGlobalSearchState,
    flightPartitions,
  };
}

export function mergeSearchControlState(
  searchControlState?: ISearchControlState | null,
  globalSearchState?: ISearchControlFormState | null,
  flightGlobalSearchState?: IFlightSearchControlFormState | null
): ISearchControlState | null {
  if (!searchControlState) return null;

  if (searchControlState.type === 'FLIGHT') {
    return mergeFlightSearchControlState(
      searchControlState,
      flightGlobalSearchState
    );
  } else {
    return mergeHotelSearchControlState(searchControlState, globalSearchState);
  }
}
